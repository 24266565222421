import s from "./style.module.css";

const Footer = () => {

  return (
    <div className={s.container}>
      © Tobiasz Wojnar 2024 - Wszelkie prawa zastrzeżone 
    </div>
  );
};

export default Footer;
